import React, { useState } from 'react'
import { MoreBar, TableWidget, VStack, useToggle } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { InterviewAvailabilityInterface } from '@src/interfaces/interviewAvailability'
import { pathToUrl } from '@src/utils/router'
import { FormattedMessage } from 'react-intl'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  interviewAvailabilityNameColumn,
  interviewAvailabilityDateColumn,
  interviewAvailabilityStatusColumn,
} from '@src/constants/columns/interviewAvailability'
import { useTable } from '@src/components/Table/hooks'
import { interviewAvailabilityRequests } from '@src/api/interviewAvailability'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import {
  getCurrentWeek,
  NavigateWeek,
  NavigationWeek,
} from '@src/components/NavigateWeek/NavigateWeek'
import { InterviewAvailabilityPreferencesSidebar } from '@src/features/InterviewAvailability/InterviewAvailabilityPreferencesSidebar'
import { InterviewAvailabilitySyncAction } from '@src/features/InterviewAvailability/InterviewAvailabilitySyncAction'

const rows: RowInterface<InterviewAvailabilityInterface> = {
  cells: [
    { ...interviewAvailabilityNameColumn, width: 100 },
    { ...interviewAvailabilityDateColumn, width: 100 },
    { ...interviewAvailabilityStatusColumn, width: 100 },
  ],
}

const getWeekRangeFilter = (week: NavigationWeek) => {
  const range = `${week.start},${week.end}`
  return [
    {
      filters: [{ id: range, name: range }],
      columnName: 'event_start_datetime',
    },
  ]
}

export const InterviewAvailabilityTablePage = () => {
  const [openInterviewAvailabilityPreferences, toggleInterviewAvailabilityPreferences] =
    useToggle()
  const employee = useSelector(selectUser)
  const employeeId = employee.id
  const [week, setWeek] = useState<NavigationWeek>(getCurrentWeek())
  const table = useTable<InterviewAvailabilityInterface, undefined>(
    interviewAvailabilityRequests,
    [
      {
        columnName: 'employee',
        filters: [{ id: employeeId, name: String(employeeId) }],
        nonResettable: true,
      },
      ...getWeekRangeFilter(week),
    ],
    [
      {
        sortBy: 'event_start_datetime',
        direction: SORT_DIRECTION.DESC,
      },
    ],
  )
  const handleWeekChange = (newWeek: NavigationWeek) => {
    setWeek(newWeek)
    table.onFilterChange(getWeekRangeFilter(newWeek))
  }
  return (
    <>
      {openInterviewAvailabilityPreferences && (
        <InterviewAvailabilityPreferencesSidebar
          employeeId={employeeId}
          onClose={() => {
            toggleInterviewAvailabilityPreferences()
          }}
          onSuccess={() => {
            table.refresh()
          }}
        />
      )}
      <PageWrapper>
        <PageHeader
          backUrl={pathToUrl(ROUTES.RECRUITMENT.INTERVIEWS)}
          title={
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.title"
              defaultMessage="Interview availability"
            />
          }
          subtitle={
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.subtitle"
              defaultMessage="Specify weekly time slots when you prefer to take interviews"
            />
          }
        />
        <PageBody maxWidth="100%">
          <TableWidget>
            <TableWidget.Actions>
              <VStack gap="s-20">
                <NavigateWeek
                  start={week.start}
                  end={week.end}
                  onNavigate={handleWeekChange}
                />
                <MoreBar>
                  <MoreBar.Action
                    useIcon="Gear"
                    onClick={() => {
                      toggleInterviewAvailabilityPreferences()
                    }}
                  >
                    <FormattedMessage
                      id="recruitment.interviewAvailability.actions.availabilityPreferences"
                      defaultMessage="Availability preferences"
                    />
                  </MoreBar.Action>
                  <InterviewAvailabilitySyncAction
                    employeeId={employeeId}
                    onSuccess={() => {
                      table.refresh()
                    }}
                  />
                </MoreBar>
              </VStack>
            </TableWidget.Actions>
            <TableWidget.Table>
              <AdjustableTable<InterviewAvailabilityInterface>
                name={TableNames.InterviewAvailability}
                useWindowScroll
                dataType="Interview slots"
                noDataMessage={
                  <FormattedMessage
                    id="recruitment.interviewAvailability.tablePage.noDataMessage"
                    defaultMessage="Interview slots will appear here."
                  />
                }
                row={rows}
                {...table}
              />
            </TableWidget.Table>
          </TableWidget>
        </PageBody>
      </PageWrapper>
    </>
  )
}
